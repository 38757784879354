html {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
}

.link {
    color:royalblue;
    text-decoration: none;
    cursor: pointer;
}

.banner-container {
    position: relative;
}

.banner-container img {
    width: 100%;
}

.dark-33 {
    filter: brightness(66%);
}

.dark-50 {
    filter: brightness(50%);
}

.dark-66 {
    filter: brightness(33%);
}

.dark-75 {
    filter: brightness(25%);
}

.fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#footer {
    position: relative; /* or 'static' */
    z-index: 1000; /* any number larger than z-index of #interface */
}
  
.glow-button {
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    transition: box-shadow 0.3s;
}
  
.glow-button:hover {
    box-shadow: 0 0 20px 5px #b73333;
    cursor: pointer;
}

.hover:hover {
    cursor: pointer;
    transform: translateY(-10px);
}

#interface {
    position: relative; /* or 'static' */
    z-index: 1;
}

#interface .row {
    justify-content: center;
    display: flex;
  }
  
.list-group.list-group-flush .list-group-item.bg-dark {
    border-color: red;
}

.list-group.list-group-flush .list-group-item.bg-danger {
    border-color: white;
}

.menu-icon {
    height: 32px;
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 25px;
    font-weight: bolder;
}

.title-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bolder;
    color: white;
}

.cross-mark {
    font-size: 24px;
    color: red;
}

.check-mark {
    font-size: 24px;
    color: green;
}

.icon-15 {
    border-radius: 15%;
}

.rectangle-container {
    position: relative;
    width: 100%;
    padding-bottom: 50%;  /* 1:2 aspect ratio */
    overflow: hidden;
}

.square-container-normal {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
}

.square-container {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
}

.square-container .fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover; /* Ensures the image fills the container */
}

.square-container img, .list-description {
    transition: opacity 0.3s ease;
}

.list-description {
    opacity: 0;
    position: absolute; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events:none;
}

.pdf-viewer {
    width: 98vw;
    height: 100vh;
}

.square-container:hover .list-description {
    opacity: 1;
    pointer-events: auto;
}

.square-container:hover img {
    filter: brightness(33%);
}

.rounded-top {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.rounded-bottom {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.rounded-left {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.rounded-top-left {
    border-top-left-radius: 10px;
}

.rounded-bottom-left {
    border-bottom-left-radius: 10px;
}
  
.rounded-right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.rounded-top-right {
    border-top-right-radius: 10px;
}

.rounded-bottom-right {
    border-bottom-right-radius: 10px;
}
  

/* Style for smaller screens */
@media only screen and (max-width: 1024px) {
    .profile-header {
        font-size: 18px; 
    }
    .text-1 {
        font-size: 14px;
    }
    .text-2 {
        font-size: 14px;
    }
  }
  
  /* Style for even smaller screens */
@media only screen and (max-width: 480px) {
    .profile-header {
        font-size: 16px; 
    }
    .text-1 {
        font-size: 12px;
    }
    .text-2 {
        font-size: 12px;
    }
}
